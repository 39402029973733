var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.asLink ? "router-link" : "div",
    {
      tag: "div",
      staticClass: "team-cell",
      attrs: {
        to: _vm.asLink
          ? Object.assign(
              {},
              {
                path: _vm.isCollegiate
                  ? "/collegiate/team/" + _vm.id
                  : "/team/" + _vm.id
              },
              _vm.to
            )
          : undefined
      },
      on: _vm._d({}, [_vm.asLink ? "click.native" : "click", _vm.trackClick])
    },
    [
      _c("ImgCell", {
        attrs: {
          image: _vm.fallbackedImage,
          label: _vm.name,
          variant: _vm.variant,
          size: _vm.size
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }